import React from "react";
import { StaticQuery, graphql } from "gatsby";
import pathBuilder from "../../utils/pathBuilder";
import { Container, Row, Column } from "../../components/System/Grids";
import PageIntro from "../../components/PageIntro/PageIntro";
import ArticleGridItem from "../../components/ArticleGridItem/ArticleGridItem";

const BlogPageTemplate = (props) => {
  const { itemList, content } = props;
  return (
    <>
      {content ? (
        <PageIntro bg="blogColorBg">
          <div
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          />
        </PageIntro>
      ) : null}
      <Container>
        <Row align="center">
          {itemList.edges.map((edge) => {
            return (
              <Column w="100" key={edge.node.slug}>
                <ArticleGridItem
                  title={edge.node.title}
                  imgFluid={edge.node.image.fluid}
                  createdAt={edge.node.createdAt}
                  slug={pathBuilder(
                    edge.node.node_locale,
                    edge.node.__typename,
                    edge.node.slug,
                  )}
                />
              </Column>
            );
          })}
        </Row>
      </Container>
    </>
  );
};
const DynamicArticlesSection = (props) => (
  <StaticQuery
    query={graphql`
      query DinamicArticlesQuery {
        en: allContentfulArticle(
          filter: { node_locale: { eq: "en" } }
          sort: { fields: [createdAt], order: [DESC] }
        ) {
          edges {
            node {
              title
              slug
              node_locale
              contentful_id
              createdAt
              image {
                fluid: gatsbyImageData(width: 1200, layout: CONSTRAINED)
              }
              __typename
            }
          }
        }
        nl: allContentfulArticle(filter: { node_locale: { eq: "nl" } }) {
          edges {
            node {
              title
              slug
              node_locale
              contentful_id
              createdAt
              image {
                fluid: gatsbyImageData(width: 1200, layout: CONSTRAINED)
              }
              __typename
            }
          }
        }
      }
    `}
    render={(data) => (
      <BlogPageTemplate
        itemList={data[props.environment.lang]}
        environment={props.environment}
      />
    )}
  />
);

export default DynamicArticlesSection;
