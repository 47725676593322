import React from "react";
import { graphql } from "gatsby";
import pathBuilder from "../../utils/pathBuilder";
import SectionRenderer from "../../components/sections/SectionRenderer";
import Layout from "../../components/Layout/Layout";

const PageTemplate = (props) => {
  const {
    node_locale,
    contentful_id,
    slug,
    seoDescription,
    seoTitle,
    __typename,
    sections,
    reservedPaths,
  } = props.data.page;

  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug, reservedPaths),
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug, reservedPaths),
      },
      {
        lang: props.data.altPage.node_locale,
        path: pathBuilder(
          props.data.altPage.node_locale,
          props.data.altPage.__typename,
          props.data.altPage.slug,
          props.data.altPage.reservedPaths,
        ),
      },
    ],

    seo: {
      title: seoTitle,
      description: seoDescription,
    },
  };
  // const pageContent = props.data.pageContent
  // const { grammarItems, grammarCategories } = props.data

  return (
    <Layout environment={environment}>
      {sections ? (
        sections.map((item, index) => (
          <SectionRenderer {...item} environment={environment} key={index} />
        ))
      ) : (
        <h1>This page is empty at the moment</h1>
      )}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query pageQuery($lang: String!, $id: String!, $altLang: String!) {
    page: contentfulPages(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      slug
      node_locale
      contentful_id
      seoTitle
      seoDescription
      __typename
      reservedPaths
      sections {
        title
        type
        content {
          childMarkdownRemark {
            html
          }
        }
        extraContent {
          cardList {
            cta
            ctaLink
            info
            cards {
              price
              title
              content {
                item
              }
              description
            }
          }
          faqItems {
            a
            q
          }
          slides {
            img
            title
            overline
          }
          theme
        }
      }
    }
    altPage: contentfulPages(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
      reservedPaths
    }
  }
`;
