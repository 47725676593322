import React, { Component } from "react";
import HeroSection from "./SectionHero";
// import CardsSection from "./SectionCards"
// import FaqSection from "./SectionFaq"
// import TinyCarrouselSection from "./SectionTinyCarrousel"
// import DinamicGrammarItems from "./SectionDinamicGrammarItems"
// import DinamicGrammarCategories from "./SectionDinamicGrammarCategories"
import DinamicArticles from "./SectionDinamicArticles";
import SectionTextBlock from "./SectionTextBlock";
// import DinamicBooks from "./SectionDinamicBooks"
// import DinamicSubscribe from "./SectionDinamicSubscribe"

export default class SectionRenderer extends Component {
  componentSwitch(type, props) {
    switch (type) {
      case "text-block":
        return <SectionTextBlock {...props} />;
      case "hero-central":
        return <HeroSection {...props} />;
      // case "card-list":
      // 	return <CardsSection {...props} />
      // case "tiny-carrousel":
      // 	return <TinyCarrouselSection {...props} />
      // case "frequently-asked-questions":
      // 	return <FaqSection {...props} />
      // case "dinamic-grammar-items":
      // 	return <DinamicGrammarItems {...props} />
      // case "dinamic-grammar-categories":
      // 	return <DinamicGrammarCategories {...props} />
      case "dinamic-articles":
        return <DinamicArticles {...props} />;
      // case "dinamic-books":
      // 	return <DinamicBooks {...props} />
      // case "dinamic-subscribe":
      // 	return <DinamicSubscribe {...props} />
      default:
        return <h1>Ninguna section coincide</h1>;
    }
  }
  render() {
    const { type } = this.props;
    return this.componentSwitch(type, this.props);
  }
}
