import React from "react";
import { Column, Container } from "../System/Grids";

export default function SectionTextBlock(props) {
  const { content } = props;

  return (
    <Container>
      <Column w="100">
        <div
          dangerouslySetInnerHTML={{
            __html: content.childMarkdownRemark.html,
          }}
        />
      </Column>
    </Container>
  );
}
